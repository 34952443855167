import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { Headers, Response, Http, RequestOptions } from '@angular/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable }  from 'rxjs';
import { ApiService } from '../api_service/api.service';
import { LoggedinService } from '../loggedin.service';
import { RoleService } from '../role.service';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.css'],
  providers: [ApiService ]
})
export class OauthComponent implements OnInit {

  token: Observable<string>;
  sessionId: Observable<string>;
  id_token:string
  access_token:string
  code:string
  refresh_token:string

  constructor(public router: Router,
    private route: ActivatedRoute,
    public http: Http,
    private loggedinService: LoggedinService,
    private roleService: RoleService,
    private _apiService: ApiService){

  } // end constructor

  ngOnInit() {

    //WHAT HAPPENS IF WRONG CODE
    this.route.queryParams.subscribe(params => {
      console.log('query',params)
      if (params){
        console.log('DOUBLE WOW',params)
        if (params.hasOwnProperty('code')==true){
          this.code = params['code']
          this.getAuthhorizationCognitoTokens(params['code'])
        } else {
          this.loggedinService.announceLoggedin(false);
          window.location.href = environment.signin
        }
        
      }
    }) //end this.route.queryParams

  }//end ngOnInit


  roles(user_groups){

    //gets user groups frin id_token
    // admin_local
    // scraper_local

    if (user_groups.indexOf('admin') > -1){
      this.roleService.announceRole('admin');
      localStorage.setItem('role','admin')
    }

    if (user_groups.indexOf('scraper') > -1){
      this.roleService.announceRole('scraper');
      localStorage.setItem('role','scraper')
    }
  } //end roles

  getAuthhorizationCognitoTokens(code){

    let body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('client_id', environment.pool_app_client_id);
    body.set('code', this.code);
    body.set('redirect_uri', environment.redirect_uri); //encodeURI(
    
    const contentHeaders = new Headers();
    contentHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    if (environment.app_client_secret!=null){
      contentHeaders.append('Authorization', 'Basic ' + btoa(environment.pool_app_client_id + ':' + environment.app_client_secret) );
    }
    
    
    this.http.post( environment.pool_url, body.toString(), { headers: contentHeaders })
    .subscribe(
      response => {
        var token_data_str = response.text()
        var token_data = JSON.parse(token_data_str)
        console.log(Object.keys(token_data))
        localStorage.setItem('id_token',token_data['id_token'])
        localStorage.setItem('access_token',token_data['access_token'])
        localStorage.setItem('refresh_token',token_data['refresh_token'])
        console.log('token_data',token_data)

        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token_data['id_token']);
        console.log(decodedToken)
        console.log(decodedToken['cognito:username'])
        const decodedAccessToken = helper.decodeToken(token_data['access_token']);
        console.log(decodedAccessToken)

        localStorage.setItem('username',decodedToken['cognito:username'])
        localStorage.setItem('email',decodedToken['email'])
        localStorage.setItem('uid',decodedToken['sub'])

        //deal with this ["us-east-1_blztYAYdB_Google", "Admin"]
        var user_groups = decodedToken['cognito:groups']
        console.log('user_groups',user_groups)
        this.roles(user_groups)
        
        //set to router can canActive
        //localStorage.setItem('role',role)
        
        //this.getPing()
        //this.testAuth();
        this.loggedinService.announceLoggedin(true);
        
        this.router.navigate(['/dashboard'])
      },
      error => {
        let error_json = JSON.parse(error.text())
        console.log(error_json)
        console.log('BIG ERROR')
        this.loggedinService.announceLoggedin(false);
        window.location.href = environment.signin
        
      }
    );
  } //end getAuthhorizationCognitoTokens


} //OauthComponent
