import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RoleService {

  constructor() { }

  private roleAnnouncedSource = new Subject<string>();
  // Observable string streams
  roleAnnounced$ = this.roleAnnouncedSource.asObservable();
  // Service message commands
  announceRole(role: string) {
    this.roleAnnouncedSource.next(role);
  }

}
 