import { Component, OnInit, OnDestroy, Renderer,ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';
import { Router,ActivatedRoute } from '@angular/router';
import { LoggedinService } from '../loggedin.service';
import * as FileSaver from 'file-saver'; 
import { Headers, Response, Http, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { ApiService } from '../api_service/api.service';
import {MatSnackBarModule,MatSnackBar,MatSnackBarRef} from '@angular/material';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SelectionModel} from '@angular/cdk/collections';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ProjectsComponent implements OnInit, OnDestroy {

  columns = [];
  displayedColumns:any
  selection:any
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  
 
  li_sub:any;
  isLoggedIn = false;

  //displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  
  constructor(public router: Router,
              private route: ActivatedRoute,
              public http: Http,
              private _apiService: ApiService,
              private loggedinService: LoggedinService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private fb: FormBuilder) { 

      if (localStorage.getItem('role')!='admin'){
        this.router.navigate(['/scraperupload'])
      }
 
  } //end constructor

  ngOnInit() {

    this.li_sub = this.loggedinService.loggedinAnnounced$.subscribe(
      isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        console.log('Dashboard isLoggedIn',this.isLoggedIn)
    }); //end this.li_sub

    
    this._apiService.getData('project').subscribe(x => {

      let temp = []
      let keys = x[0]['meta']
      temp = Object.keys(keys)

      console.log(temp)
      
      let coldef_list = [];
      let header_list = [];
      for (let i = 0; i < temp.length; i++) { 
          coldef_list.push(temp[i].replace(/\s/g, "_"));
          header_list.push(temp[i])
      }

      for (let i = 0; i < coldef_list.length; i++) { 
        let ff = `row.${coldef_list[i]}`
        var tt = { columnDef: `${coldef_list[i]}`,    header: `${header_list[i]}`,  cell: (row) => eval(ff)   }
        this.columns.push(tt)
      }

      this.displayedColumns = this.columns.map(x => x.columnDef);
      this.displayedColumns.unshift('select')

      let data = []
      for (let i = 0; i < x.length; i++) { 
        data.push(this.createData(x[i])); 
      }

      //this.dataSource = new MatTableDataSource<Element>(this.columns);

      

      this.dataSource = new MatTableDataSource(data);
      this.selection = new SelectionModel(true, []);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }); //end get data
  
    

  } //end ngOnInit

  

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  
  isAllSelected() {

    //console.log('adfadfasfaf')

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  createData(point){
    let data = {}
    Object.keys(point['meta']).forEach(function (key) { 
        data[key.replace(/\s/g, "_")] = point['meta'][key];
    })
    return data
  } //createData

  styleCell(col_name,row){

    /*
    if (col_name['columnDef']=='progress' && row['progress']=='100'){
      return {'color' : 'red'}
    } else {
        return {}
    }
    */


    return {}
  }


  ngOnDestroy(){

    if(this.li_sub){
      this.li_sub.unsubscribe();
    }

  } //end ngOnDestroy







} //end ProjectsComponent





export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];



































/*
upload data as csv 
insert data into table as csv
store data in mysql


load data in angular table
Allow edits and add a status field

create job assinment page
user gets a job from the pool and is marked as pending 
user runs the job uploads data and marks the job as complete














* Get a list of users
* Get list of projects
* Get list fake facebook accounts

1) User selects a project
2) a facebook account is selected
3) users does scrapping
4) user uploads data with the account
5) account is placed back into the pool
6) have tne ablity to select another account











*/