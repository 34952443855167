import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { Headers, Response, Http, RequestOptions } from '@angular/http';
import { PollingService } from './polling.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoggedinService } from './loggedin.service';
import { ApiService } from './api_service/api.service';
import { Observable, interval, pipe } from 'rxjs';
import { switchMap, map, takeWhile } from 'rxjs/operators';
import {MatSnackBarModule,MatSnackBar,MatSnackBarRef} from '@angular/material';
import { RoleService } from './role.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


      polling_sub:any;
      polling:object;
      isLoggedIn=false;
      li_sub:any;
      role_sub:any;
      role:string;

      constructor(public router: Router,
          private route: ActivatedRoute,
          public http: Http,
          private loggedinService: LoggedinService,
          private roleService: RoleService,
          public snackBar: MatSnackBar,
          private _apiService: ApiService,){

          this.polllingTokenRefreshService();

          console.log('role:',localStorage.getItem('role'))

          this.role = localStorage.getItem('role');

      } // end contructor


      openSnackBar(message: string, action: string) {
        let snackBarRef = this.snackBar.open(message, action, {
          duration: 30000,
        });

        snackBarRef.afterDismissed().subscribe(() => {
          console.log('after dismiss refresh_token');
        });
        snackBarRef.onAction().subscribe(() => {
          this._apiService.refreshToken()
          console.log('action refresh_token');
        });
        //snackBarRef.dismiss();
      } //end openSnackBar
    

      
      
      ngOnInit(){


        this.role_sub = this.roleService.roleAnnounced$.subscribe(
          role => {
            this.role = role;
        }); //end role_sub

        

        //HANDLES REDRECTS AFTER LOGIN
        this.li_sub = this.loggedinService.loggedinAnnounced$.subscribe(
          isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        }); //end this.li_sub

        //HANDLE BROWSER REFRESH 
        if (localStorage.hasOwnProperty('id_token')==true){
          
              const helper = new JwtHelperService();
              const isExpired = helper.isTokenExpired(localStorage['id_token']);
              if (isExpired==true){
                this.logout()
              } else {
                console.log('BROWSER REFRESH.....')
                this.isLoggedIn = true;
              }
        } else {
          //this.isLoggedIn = false;
          this.loggedinService.announceLoggedin(false);
          //this.logout();
        
        } //end localStorage.hasOwnProperty('id_token')
        
      } //end ngOnInit

      polllingTokenRefreshService(){
         //If token is expired then logout
         //Add modal to continue the session if yes then call refresh token
         interval(10000).subscribe(x => {

              const helper = new JwtHelperService();
              const isExpired = helper.isTokenExpired(localStorage['id_token']);
              var current_time = new Date().getTime() / 1000;
              const expirationDate = helper.getTokenExpirationDate(localStorage['id_token']);
              const decodedToken = helper.decodeToken(localStorage['id_token']);
              var time_left = decodedToken['exp']-current_time;
              //console.log('time_left:',time_left)
              if (time_left<30){
                console.log('you will be logged out in 30 seconds')
                this.openSnackBar('Your session will expire in 30 seconds','Continue?')
              }

              if (isExpired==true){
                this.logout()
              }
          //console.log(isExpired)
         })

      } //end polllingCallService
    

      getLogout(){
          var qs = "?client_id=" + environment.pool_app_client_id + "&logout_uri=" + environment.logout_uri;
          console.log(environment.pool_url + qs)
          this.http.get( environment.pool_url + qs)
          .subscribe(
          response => {
          console.log(response.text())
          },
          error => {
          let error_json = JSON.parse(error.text())
          console.log(error_json)
          }
          );
      } //end getLogout()

      ngOnDestroy(){


      }

      logout(){

        //this.isLoggedIn=false;
        if(this.li_sub){
          this.li_sub.unsubscribe();
        }
        if(this.role_sub){
          this.role_sub.unsubscribe();
        }

        localStorage.clear();
        sessionStorage.clear();
        //this.loggedinService.announceLoggedin(false);
        window.location.href = environment.signin
      } //end OnDestroy

}


@Component({
  selector: 'token-snack-bar',
  template: `Your session will expire in 30 Seconds`,
  styles: [],
})
export class TokenExpireComponent {}