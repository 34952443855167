import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, Response, Http, RequestOptions } from '@angular/http';
import { environment } from '../environments/environment';
//import { tokenNotExpired } from 'angular2-jwt';
import { LoggedinService } from './loggedin.service';
//import { BusyService } from '../busy.service';
import { ApiService } from './api_service/api.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              public http: Http,
              private _apiService: ApiService,
              private loggedinService: LoggedinService
              ) {}


  canActivate() {

    if (localStorage.hasOwnProperty('id_token')==true && localStorage.hasOwnProperty('refresh_token')==true){
      const helper = new JwtHelperService();
      const isExpired = helper.isTokenExpired(localStorage['id_token']);
      const decodedToken = helper.decodeToken(localStorage['id_token']);
      console.log('isExpired:',isExpired)

      //If less than 5 minutes left then the refresh token
      var current_time = new Date().getTime() / 1000;
      let time_left = decodedToken['exp']-current_time;
      if (time_left<300){
        this._apiService.getRefreshCognitoTokens()
      }

      if (isExpired==true){
        //problem here...we need to wait for reqeust to complete I think
        //this._apiService.refreshToken()
        this._apiService.getRefreshCognitoTokens()
      } else {
        this.loggedinService.announceLoggedin(true);
        console.log('AUTH GAURD Works')
        return true
      }
    } else {
      window.location.href = environment.signin
    }

    if (localStorage.hasOwnProperty('id_token')==false){
      localStorage.clear();
      sessionStorage.clear();
      this.loggedinService.announceLoggedin(false);
      window.location.href = environment.signin
      return false
    }





   /*
   private loggedinService: LoggedinService,
    if (tokenNotExpired()) {
      

      this.loggedinService.announceLoggedin(true);
      return true;
    }
    */
   
    //this.loggedinService.announceLoggedin(false);
    //localStorage.removeItem('id_token');
    //this.router.navigate(['/login']);
    return false;
    
  }
}
