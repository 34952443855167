import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class LoggedinService {

  constructor() { }

  private loggedinAnnouncedSource = new Subject<boolean>();
  // Observable string streams
  loggedinAnnounced$ = this.loggedinAnnouncedSource.asObservable();
  // Service message commands
  announceLoggedin(loggedin: boolean) {
    this.loggedinAnnouncedSource.next(loggedin);
  }

}
 