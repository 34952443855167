export const environment = {
  production: false,
  API_URL: "https://di83w7yjr0.execute-api.us-east-1.amazonaws.com/development/",
  environment: "development",
  pool_id: "us-east-1_c41droeWO", 
  pool_app_client_id: "3aga2fu6vjsnjj52chqbi394i",
  app_client_secret: "1a6jnqvbvkhbhqptjdnmtua43012qe0qrjv50ek8gg0id75d1vaf",
  redirect_uri: "https://dev-dashboard.promoscore.com/oauth2.html",
  logout_uri: "https://dev-dashboard.promoscore.com/logout",
  pool_url: "https://lux-pool-development.auth.us-east-1.amazoncognito.com/oauth2/token",
  signin: "https://lux-pool-development.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=3aga2fu6vjsnjj52chqbi394i&redirect_uri=https://dev-dashboard.promoscore.com/oauth2.html"
};