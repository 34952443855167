

import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { OauthComponent } from './oauth/oauth.component';
import { ProjectsComponent } from './projects/projects.component';
import { DemosComponent } from './demos/demos.component';


import { ScraperUploadComponent } from './scraper-upload/scraper-upload.component';
import { AuthGuard } from './auth.guard';


export const ROUTES: Routes = [

    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'dashboard',  component: DashboardComponent,canActivate: [AuthGuard]},
    { path: 'login',  component: LoginComponent},
    { path: 'logout',  component: LogoutComponent, canActivate: [AuthGuard]},
    { path: 'oauth',  component: OauthComponent},
    { path: 'scraperupload',  component: ScraperUploadComponent, canActivate: [AuthGuard]},
    { path: 'projects',  component: ProjectsComponent, canActivate: [AuthGuard]},
    { path: 'demos',  component: DemosComponent, canActivate: [AuthGuard]}
    
    
]
