import { Component, OnInit, OnDestroy, Renderer,ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';
import { Router,ActivatedRoute } from '@angular/router';
import { LoggedinService } from '../loggedin.service';
import * as FileSaver from 'file-saver'; 
import { Headers, Response, Http, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { ApiService } from '../api_service/api.service';
import {MatSnackBarModule,MatSnackBar,MatSnackBarRef} from '@angular/material';
import { THIS_EXPR, ThrowStmt } from '../../../node_modules/@angular/compiler/src/output/output_ast';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
 

@Component({
  selector: 'app-scraper-upload',
  templateUrl: './scraper-upload.component.html',
  styleUrls: ['./scraper-upload.component.css']
})
export class ScraperUploadComponent implements OnInit, OnDestroy {

  //https://stackoverflow.com/questions/47067060/angular-4-reactive-form-is-not-clearing-the-validations-after-resetting-the-form/47068454#47068454
  @ViewChild('f') myNgForm;

  columns = [];
  displayedColumns:any
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel(true, []);

  isGetProject = true;
  dataNotReady = true;

  li_sub:any;
  isLoggedIn = false;
  myForm: FormGroup; 
  data:any;
  is_file=true;
  filename:string;
  dbbusy=false;
  username:string;


  scraper_tool_array = [
    {displayname:'FB Marketing Automation'}
  ]

  id_type_array = [
    {displayname:'email'},
    {displayname:'uid'}
  ]

  project_array = [
    {displayname:'Test1'},
    {displayname:'Test2'},
  ]

  project_id_list = [];
  
  constructor( public http: Http,
              private _apiService: ApiService,
              private loggedinService: LoggedinService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private fb: FormBuilder) {


                this.myForm = fb.group({
                  scraper_tool: [,[Validators.required]],
                  projects: [,[Validators.required]],
                  id_type: [,[Validators.required]],
                  fb_group_page: [,[Validators.required]],
                  filename: [,[Validators.required]]
               })
    
   } //end constructor

  
  ngOnInit() {

    this.username = localStorage.getItem('username')

    this.li_sub = this.loggedinService.loggedinAnnounced$.subscribe(
      isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        console.log('Dashboard isLoggedIn',this.isLoggedIn)
    }); //end this.li_sub
 
    this.getProjectDataTable()

  } //end ngOnInit

  
  ngOnDestroy(){

    if(this.li_sub){
      this.li_sub.unsubscribe();
    }

  } //end ngOnDestroy

  MAX_CHECKED = 1;
  checked_count = 0;


  checkRow(){
    this.selection.clear() 
    let id = this.myForm.controls['projects'].value
    this.dataSource.data.forEach(row => {
      if (row['id']==id){
        this.selection.select(row)
      }
    });
  } //end checkRow()



  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    //console.log('numSelected',numSelected,'numRows',numRows,numSelected === numRows)
    
    return numSelected === numRows;

  } // isAllSelected()

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  } // end masterToggle()

  getProjectMeta(x){

    console.log('x',x.length)
    this.project_id_list = []

    for (let i = 0; i < x.length; i++) { 
      if (x[i]['status']==null){
        //console.log(x[i]['meta']['id'],x[i]['status'])
        this.project_id_list.push({displayname:x[i]['meta']['id']})
      }
    }

  } // end getProjectMeta

  getProjectDataTable(){


    let data = {}
    data['username'] = this.username
    this._apiService.postData('project/assign/list',data).subscribe(x => {

      this.columns = [];
      this.displayedColumns = null

      console.log(x)

      let temp = []
      if (x.length>0){

            this.getProjectMeta(x)
            let keys = x[0]['meta']
            temp = Object.keys(keys)

            
            var index = temp.indexOf('Outlet');
            if (index !== -1) temp.splice(index, 1);

            var index = temp.indexOf('BrandID');
            if (index !== -1) temp.splice(index, 1);

            var index = temp.indexOf('Email Address');
            if (index !== -1) temp.splice(index, 1);

            var index = temp.indexOf('County');
            if (index !== -1) temp.splice(index, 1);

            var index = temp.indexOf('Zip Code');
            if (index !== -1) temp.splice(index, 1);

            var index = temp.indexOf('Country');
            if (index !== -1) temp.splice(index, 1);

           


            console.log('temp:',temp)
            
            let coldef_list = [];
            let header_list = [];
            for (let i = 0; i < temp.length; i++) { 
                coldef_list.push(temp[i].replace(/\s/g, "_"));
                header_list.push(temp[i])

            }

            for (let i = 0; i < coldef_list.length; i++) { 
              let ff = `row.${coldef_list[i]}`
              var tt = { columnDef: `${coldef_list[i]}`,    header: `${header_list[i]}`,  cell: (row) => eval(ff)   }
              this.columns.push(tt)
            }

            this.displayedColumns = this.columns.map(x => x.columnDef);
            this.displayedColumns.unshift('select')
            //this.displayedColumns.push('star')

            
            let data = []
            for (let i = 0; i < x.length; i++) { 
              data.push(this.createData(x[i])); 
            }

            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.dataNotReady=false;
            console.log('this.selection.selected.length',this.selection.selected.length)
        }//end if x
    }); //end get data

  } //end getProjectDataTable

  createData(point){
    let data = {}
    Object.keys(point['meta']).forEach(function (key) { 
        data[key.replace(/\s/g, "_")] = point['meta'][key];
    })
    return data
  } //createData

  styleCell(col_name,row){

    /*
    if (col_name['columnDef']=='progress' && row['progress']=='100'){
      return {'color' : 'red'}
    } else {
        return {}
    }
    */

    return {}
  } //end styleCell


  getProject(){

    let data = {}
    data['username'] = this.username
    this.isGetProject = false;
    this._apiService.postData('project/assign',data).subscribe(x => {
      this.isGetProject = true;
      this.columns = [];
      this.displayedColumns = null
      this.getProjectDataTable()
    });
    
  } //getProject






  openUploadDialog(){

     let dialogRef = this.dialog.open(DialogUpload);
     //dialogRef.componentInstance.model_id = mid;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
     //dialogRef.componentInstance.model = model;
     dialogRef.afterClosed().subscribe(result => {
      console.log('WOW',result)
       if (result){
        this.myForm.controls['filename'].setValue(result['filename']);
        this.data = result['data']
           if (result.status==false){
             //localStorage.setItem('excel_msg', result.msg);
             //this.openUploadErrorSnackBar(result.msg)
           } 
           if (result.status==true){
             //console.log()
           }
         } //end if result
     }); 

 }//end openUploadDialog
 
 openUploadSnackBar(message: string, action: string) {
  let snackBarRef = this.snackBar.open(message, action, {
    duration: 10000,
    });
  } //end openSnackBar

  reset_form(){

  }

  onSubmit(){


    //this.myForm.controls['analysis_horizon'].value

    let data = this.myForm.value
    data['base64'] = this.data
    data['username'] = localStorage.getItem('username')
    data['uid'] = localStorage.getItem('uid')
    data['email'] = localStorage.getItem('email')
    data['role'] = localStorage.getItem('role')

    console.log(data)

    this.myForm.disable();
    this.dbbusy = true;
    this.isGetProject=false;

  
    this._apiService.postData('upload',data).subscribe(x => {
        console.log(x)
        this.openUploadSnackBar(data['filename'] + ' has been uploaded','')
        this.myForm.reset()
        this.myNgForm.resetForm(); //http://next.plnkr.co/edit/sRCiYvRqGevK493w79A4?p=preview&preview https://github.com/angular/material2/issues/4190
        this.is_file=true;
        this.data=undefined;
        this.dbbusy=false;
        this.myForm.enable()
        this.getProjectDataTable()
        this.isGetProject=true;
    });
  
    

   

   //this.myForm.reset()
   //this.myForm.controls['scraper_tool'].markAsPristine()
   //this.myForm.controls['scraper_tool'].markAsUntouched()
   //this.myForm.controls['scraper_tool'].untouched

    
    
  } //end onSubmit

}


/*
UPLOAD SNACKBAR
------------------------------------------------------------------------------------------------
*/

@Component({
  selector: 'upload-snack-bar',
  template: ``,
  styles: [],
})
export class UploadSnackbarComponent {}

/*
UPLOAD
------------------------------------------------------------------------------------------------
*/

@Component({
  selector: 'upload_dialog',
  templateUrl: './upload_dialog.html',
})
export class DialogUpload implements OnDestroy {
  

  selectedValue:string;
  myForm: FormGroup;
  model_id:string;
  model:any;
  data:any;
  is_file=true;
  filename:string;

  constructor(public dialogRef: MatDialogRef<DialogUpload>,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public router: Router,
              private renderer: Renderer,
                ) {

 

  } //end constructor

  ngOnDestroy() {

  }



  fileChange(event){
    //https://stackoverflow.com/questions/40214772/file-upload-in-angular
    //https://nehalist.io/uploading-files-in-angular2/
    //data:application/json;base64,eyesrzyRDW

    let reader = new FileReader();
    let file = event.target.files[0];
    this.filename = file.name;
    reader.readAsDataURL(file);
    reader.onload = () => {
    this.data = reader.result.toString().split(',')[1];
    this.is_file=false;

    let data = {}
    data['filename'] = this.filename
    data['data']=this.data

    this.dialogRef.close(data)


    }
  }


}