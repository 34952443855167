import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';

import { LoggedinService } from '../loggedin.service';
import * as FileSaver from 'file-saver'; 
import { Headers, Response, Http, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { ApiService } from '../api_service/api.service';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit,OnDestroy {

  /*
   user id's  facebook emails via xtractor 2 - download csv    search posted and liked
facebook audiance blaster  search page group use page number  pull meambers of the page


  */


  li_sub:any;
  isLoggedIn = false;
  myForm: FormGroup; 
  data:any;
  is_file=true;
  filename:string;


  
  constructor( public http: Http,
              private _apiService: ApiService,
              private loggedinService: LoggedinService,
              private fb: FormBuilder) {


   } //end constructor

  
  ngOnInit() {

    this.li_sub = this.loggedinService.loggedinAnnounced$.subscribe(
      isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        console.log('Dashboard isLoggedIn',this.isLoggedIn)
    }); //end this.li_sub

  } //end ngOnInit

  
  ngOnDestroy(){

    if(this.li_sub){
      this.li_sub.unsubscribe();
    }

  } //end ngOnDestroy


  



}


