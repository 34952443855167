import { Injectable } from '@angular/core';
import {Http,Headers} from '@angular/http';
import { Observable, interval, pipe } from 'rxjs';
import { switchMap, map, takeWhile } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoggedinService } from '../loggedin.service';

@Injectable()
export class ApiService {

  private domain =  environment.API_URL;
  private _url = environment.API_URL;
  
  constructor(private _http: Http, 
              private _authHttp: HttpClient,
              private loggedinService: LoggedinService){
              
  }


  isTokenExpired(){

    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(localStorage.getItem('id_token'));
    var isIdTokenExpired = helper.isTokenExpired(localStorage.getItem('id_token'));
    //console.log('refresh_token',localStorage.getItem('refresh_token'))
    //const isRefreshTokenExpired = helper.isTokenExpired(localStorage.getItem('refresh_token'));
    console.log('id_token isExpired:',isIdTokenExpired)
    //console.log('refresh_token isExpired:',isRefreshTokenExpired)

    //isIdTokenExpired=true;
    if (isIdTokenExpired==true){
      this.getRefreshCognitoTokens()
    }

  }

  //ON SUBMIT CHECK IF TOKEN NEEDED REFRESHING
  getRefreshCognitoTokens(){

    console.log('API_SERVICE WE REFRESHED THE TOKENS')
    let body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('client_id', environment.pool_app_client_id);
    body.set('refresh_token', localStorage.getItem('refresh_token'));

    const contentHeaders = new Headers();
    contentHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (environment.app_client_secret!=null){
      contentHeaders.append('Authorization', 'Basic ' + btoa(environment.pool_app_client_id + ':' + environment.app_client_secret) );
    }

    var result = this._http.post( environment.pool_url, body.toString(), { headers: contentHeaders })
        .subscribe(
          response => {
            var token_data_str = response.text()
            var token_data = JSON.parse(token_data_str)
            console.log(Object.keys(token_data))
            localStorage.setItem('id_token',token_data['id_token'])
            localStorage.setItem('access_token',token_data['access_token'])
            console.log('REFRESH token_data',token_data)
          },
          error => {
            let error_json = JSON.parse(error.text())
            console.log(error_json)
            this.loggedinService.announceLoggedin(false);
            window.location.href = environment.signin
          }
        );

    return result
  } //end getRefreshCognitoTokens()

  refreshToken(){
    var result = this.getRefreshCognitoTokens()
    return result
  }
 
  getData(endpoint:string,isHash=true){

    this.isTokenExpired()
    const contentHeaders = new Headers();
    contentHeaders.append('Authorization', localStorage.getItem('id_token'));

    var url = this.getEndpointUrl(endpoint);
    var result = this._http.get(this.getEndpointUrl(endpoint),{ headers: contentHeaders }).pipe(map(res => res.json()));
     
		return result;
  }

  postData(endpoint:string,data,isHash=true,is_cache_buster=true){

      if (is_cache_buster==true){
        let ran = Math.floor(Math.random() * 10000) + 1;
        var cache_buster = '?cache=' + ran.toString();
        endpoint = endpoint + cache_buster;
      }
      
      this.isTokenExpired()
      const contentHeaders = new Headers();
      contentHeaders.append('Authorization', localStorage.getItem('id_token'));
      contentHeaders.append('Content-Type', 'application/json');

      var url = this.getEndpointUrl(endpoint);
      var result =this._http.post(this.getEndpointUrl(endpoint),JSON.stringify(data),{ headers: contentHeaders }).pipe(map(res => res.json()));
    
      return result;
  }

  private getEndpointUrl(endpoint:string){
      return this._url + endpoint;
  }
  


}