import { Component, OnInit, OnDestroy, Renderer,ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';
import { Router,ActivatedRoute } from '@angular/router';
import { LoggedinService } from '../loggedin.service';
import * as FileSaver from 'file-saver'; 
import { Headers, Response, Http, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { ApiService } from '../api_service/api.service';
import {MatSnackBarModule,MatSnackBar,MatSnackBarRef} from '@angular/material';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SelectionModel} from '@angular/cdk/collections';
import * as _ from "lodash";

@Component({
  selector: 'app-demos',
  templateUrl: './demos.component.html',
  styleUrls: ['./demos.component.css']
})
export class DemosComponent implements OnInit, OnDestroy {


  columns = [];
  displayedColumns:any
  selection:any
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  li_sub:any;
  isLoggedIn = false;

  constructor(public router: Router,
    private route: ActivatedRoute,
    public http: Http,
    private _apiService: ApiService,
    private loggedinService: LoggedinService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private fb: FormBuilder) { 

    if (localStorage.getItem('role')!='admin'){
        this.router.navigate(['/scraperupload'])
    }

} //end constructor

  ngOnInit() {

    this.li_sub = this.loggedinService.loggedinAnnounced$.subscribe(
      isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        console.log('Dashboard isLoggedIn',this.isLoggedIn)
    }); //end this.li_sub


    this._apiService.getData('demos').subscribe(x => {
      console.log(x)

      let temp = []
      let keys = x[0]
      temp = Object.keys(keys)

      console.log(temp)
      
      
      let coldef_list = [];
      let header_list = [];
      for (let i = 0; i < temp.length; i++) { 
          coldef_list.push(temp[i].replace(/\s/g, "_"));
          header_list.push(temp[i])
      }

      
      for (let i = 0; i < coldef_list.length; i++) { 
        let ff = `row.${coldef_list[i]}`
        var tt = { columnDef: `${coldef_list[i]}`,    header: `${header_list[i]}`,  cell: (row) => eval(ff)   }
        this.columns.push(tt)
      }

      
      this.displayedColumns = this.columns.map(x => x.columnDef);
      this.displayedColumns.unshift('select')

      
      let data = []
      for (let i = 0; i < x.length; i++) { 
        data.push(this.createData(x[i])); 
      }

      
      this.dataSource = new MatTableDataSource(data);
      this.selection = new SelectionModel(true, []);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      

    });//end getData

  } //end ngOnInit

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  
  isAllSelected() {

    //console.log('adfadfasfaf')

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  createData(point){
    let data = {}
    Object.keys(point).forEach(function (key) { 
        data[key.replace(/\s/g, "_")] = point[key];
    })
    return data
  } //createData

  styleCell(col_name,row){

    /*
    if (col_name['columnDef']=='progress' && row['progress']=='100'){
      return {'color' : 'red'}
    } else {
        return {}
    }
    */


    return {}
  }






  ngOnDestroy(){

    if(this.li_sub){
      this.li_sub.unsubscribe();
    }

  } //end ngOnDestroy


}
 