import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';

import { AppComponent,TokenExpireComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { OauthComponent } from './oauth/oauth.component';
import { ScraperUploadComponent, DialogUpload, UploadSnackbarComponent } from './scraper-upload/scraper-upload.component';
import { ProjectsComponent } from './projects/projects.component';
import { DemosComponent } from './demos/demos.component';


import { ApiService } from './api_service/api.service';
import { PollingService } from './polling.service';
import { AuthGuard } from './auth.guard';
import { LoggedinService } from './loggedin.service';
import { RoleService } from './role.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtModule } from '@auth0/angular-jwt';
export function tokenGetter() {
  return localStorage.getItem('id_token');
}


import {MatButtonModule, MatCheckboxModule,MatMenuModule,MatIconModule,MatToolbarModule,MatCardModule,
  MatInputModule,MatDialogModule,MatSnackBarModule,MatButtonToggleModule,MatSidenavModule,MatListModule,
  MatProgressBarModule,MatProgressSpinnerModule,MatSelectModule,MatRadioModule,MatDatepickerModule, MatNativeDateModule,
  MatTooltipModule,MatSlideToggleModule,MatTabsModule,MatExpansionModule,MatTableModule,MatPaginatorModule,
  MatDividerModule,MatSortModule } from '@angular/material';

  

@NgModule({
  declarations: [
    AppComponent,TokenExpireComponent,
    DashboardComponent,
    LogoutComponent,
    LoginComponent,
    OauthComponent,
    ScraperUploadComponent,
    DialogUpload,UploadSnackbarComponent, ProjectsComponent, DemosComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,HttpModule,
    MatButtonModule, MatCheckboxModule,MatMenuModule,MatIconModule,MatToolbarModule,MatInputModule,
    MatCardModule,MatDialogModule,MatSnackBarModule,MatButtonToggleModule,MatSidenavModule,MatListModule,
    MatDatepickerModule, MatNativeDateModule,MatTooltipModule,MatSlideToggleModule,MatTabsModule,
    MatSelectModule,MatRadioModule,MatExpansionModule,MatTableModule, MatPaginatorModule,
    MatProgressBarModule,MatProgressSpinnerModule,MatDividerModule,MatSortModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        headerName: 'Authorization',
        authScheme: '',
        skipWhenExpired: true
      }
    }),
    RouterModule.forRoot(ROUTES, { useHash: true })
  ],
  entryComponents: [
    TokenExpireComponent,
    DialogUpload,UploadSnackbarComponent
  ],
  providers: [
    LoggedinService,
    JwtHelperService,
    ApiService,
    PollingService,
    AuthGuard,
    RoleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
